import React, {Component} from "react";
import {Fade, Slide} from "react-reveal";

// eslint-disable-next-line require-jsdoc
class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const email = this.props.data.email;
    const contactmessage = this.props.data.contactmessage;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{contactmessage}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">
              <form action="https://public.herotofu.com/v1/14d2e1d0-baac-11ee-870a-ff8e0d81300a" method="post"
                acceptCharset="UTF-8" id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="name"
                      name="Name"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      defaultValue=""
                      size="35"
                      id="email"
                      name="Email"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Message <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                    ></textarea>
                  </div>

                  <div>
                    <button className="submit">Submit</button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif"/>
                    </span>
                  </div>
                </fieldset>
                <div className="antibotField"
                  aria-hidden="true">
                  <input type="text" name="_gotcha" tabIndex="-1" autoComplete="off"/>
                </div>
              </form>

              <div id="message-warning"> Error</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br/>
              </div>
              <span style={{color: "#fff"}}>Copyright © 2023 - 2024 FeelWell.AI</span>
            </div>
          </Slide>

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>Email</h4>
                <p className="address">
                  <span>{email}</span>
                </p>
              </div>

              {/*      <div className="widget widget_tweets">*/}
              {/*        <h4 className="widget-title">Latest Tweets</h4>*/}
              {/*        <ul id="twitter">*/}
              {/*          <li>*/}
              {/*            <span>*/}
              {/*              This is Photoshop's version of Lorem Ipsum. Proin gravida*/}
              {/*              nibh vel velit auctor aliquet. Aenean sollicitudin, lorem*/}
              {/*              quis bibendum auctor, nisi elit consequat ipsum*/}
              {/*              <a href="./">http://t.co/CGIrdxIlI3</a>*/}
              {/*            </span>*/}
              {/*            <b>*/}
              {/*              <a href="./">2 Days Ago</a>*/}
              {/*            </b>*/}
              {/*          </li>*/}
              {/*          <li>*/}
              {/*            <span>*/}
              {/*              Sed ut perspiciatis unde omnis iste natus error sit*/}
              {/*              voluptatem accusantium doloremque laudantium, totam rem*/}
              {/*              aperiam, eaque ipsa quae ab illo inventore veritatis et*/}
              {/*              quasi*/}
              {/*              <a href="./">http://t.co/CGIrdxIlI3</a>*/}
              {/*            </span>*/}
              {/*            <b>*/}
              {/*              <a href="./">3 Days Ago</a>*/}
              {/*            </b>*/}
              {/*          </li>*/}
              {/*        </ul>*/}
              {/*      </div>*/}
            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;

import React, {Component} from "react";
import Fade from "react-reveal";
import {BrowserView} from "react-device-detect";

let id = 0;
// eslint-disable-next-line require-jsdoc
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map((projects) => {
      const projectImage = "images/screenshots/" + projects.image;

      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <img src={projectImage} alt={projects.image}/>
          </div>
        </div>
      );
    });

    const appStoreButtons = this.props.data.badges.map((badge) => {
      const badgeImage = `images/badges/${badge.image}_badge.png`;
      return (
        <a href={badge.url} target="_blank" rel="noreferrer">
          <img src={badgeImage} alt={badge.image} className="badge"/>
        </a>
      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <BrowserView>
              <div className="twelve columns collapsed">
                <h1>Feel Well AI Pain Monitor App</h1>

                <div
                  id="portfolio-wrapper"
                  className="bgrid-fifth s-bgrid-fifth cf">
                  {projects}
                </div>
              </div>
            </BrowserView>
            <h1>Download for FREE</h1>
            <div className="center">
              {appStoreButtons}
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;

import React, {Component} from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const description = this.props.data.description;
    const details = this.props.data.details;

    return (
      <header id="home">
        <ParticlesBg type="lines" bg={true}/>

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Apps
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#how-it-works">
                How It Works
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>
        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <img src={"images/bootsplash_logo.png"} alt={"mobile_logo"} width={300} height={300}/>
              <h1 className="responsive-headline" style={{marginTop: -80}}>{name}</h1>
            </Fade>
            <Fade bottom duration={2500}>
              <h2>{description}</h2>
            </Fade>
            <Fade bottom duration={3600}>
              <h6>{details}</h6>
            </Fade>
            <hr/>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;

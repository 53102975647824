import React, {Component} from "react";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUsage from "./Pages/TermsOfUsage";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import Portfolio from "./Components/Portfolio";
import About from "./Components/About";
import Contact from "./Components/Contact";
import HowItWorks from "./Components/HowItWorks";

// eslint-disable-next-line require-jsdoc
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeData: {},
    };

    // ReactGA.initialize("UA-110570651-1");
    // ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    return (
      <BrowserRouter>
        <nav id="bottom-links">
          <div class="centered-div">
            <Link to="/" style={{padding: 5}}>
              Home
            </Link>
            <Link to="/privacy" style={{padding: 5}}>
              Privacy Policy
            </Link>
            <Link to="/terms" style={{padding: 5}}>
              Terms of Use
            </Link>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<Header data={this.state.resumeData.main}/>}/>
          <Route path="/privacy" element={<PrivacyPolicy/>}/>
          <Route path="/terms" element={<TermsOfUsage/>}/>
        </Routes>
        <Portfolio data={this.state.resumeData.portfolio}/>
        <About data={this.state.resumeData.about}/>
        <HowItWorks data={this.state.resumeData.how_it_works}/>
        <Contact data={this.state.resumeData.main}/>
      </BrowserRouter>
    );
  }
}

export default App;

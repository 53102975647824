import React, {Component} from "react";
import Fade from "react-reveal";
import ReactPlayer from "react-player";

// eslint-disable-next-line require-jsdoc
class HowItWorks extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.promos.map((promo) => {
      const promoVideo = `video/promos/promo_${promo.video_id}.mov`;

      return (
        <div key={promo.video_id} className="columns portfolio-item">
          <div style={{height: "100%"}}>
            <ReactPlayer
              url={promoVideo}
              width='100%'
              height='100%'
              controls={false}
              loop
              muted
              playsinline
              playing
            />
            <div style={{height: 1, width: "90%", backgroundColor: "#ccd2d2", marginLeft: "5%"}}/>
            <h2 style={{padding: 10}}>{`Step ${promo.video_id}`}</h2>
            <p style={{paddingLeft: 10, paddingRight: 10}}>{promo.text}</p>
          </div>
        </div>
      );
    });

    return (
      <section id="how-it-works">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>How It Works</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-thirds s-bgrid-thirds cf">
                {projects}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default HowItWorks;

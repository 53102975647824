import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{padding: 32}}>
      <h1 style={{marginBottom: 12, textAlign: "center"}}>Basic</h1>
            You may not post violent, nude, partially nude, discriminatory, unlawful, infringing, hateful, pornographic
            or
            sexually suggestive photos or other content via the Service or Social Media page.
            You are responsible for any activity that occurs through your account and you agree you will not sell,
            transfer,
            license or assign your account, followers, username, or any account rights. With the exception of people or
            businesses that are expressly authorized to create accounts on behalf of their employers or clients,
            FeelWell
            prohibits the creation of and you agree that you will not create an account for anyone other than yourself.
            You
            also represent that all information you provide or provided to FeelWell upon registration and at all other
            times will be true, accurate, current and complete and you agree to update your information as necessary to
            maintain its truth and accuracy.
            You agree that you will not solicit, collect or use the login credentials of other FeelWell users.
            You are responsible for keeping your password secret and secure.
            You must not defame, stalk, bully, abuse, harass, threaten, impersonate or intimidate people or entities and
            you
            must not post private or confidential information via the Service, including, without limitation, your or
            any
            other person’s credit card information, social security or alternate national identity numbers, non-public
            phone
            numbers or non-public email addresses.
            You may not use the Service for any illegal or unauthorized purpose. You agree to comply with all laws,
            rules
            and regulations (for example, federal, state, local and provincial) applicable to your use of the Service
            and
            your Content (defined below), including but not limited to, copyright laws.
            You are solely responsible for your conduct and any data, text, files, information, usernames, images,
            graphics,
            photos, profiles, audio and video clips, sounds, musical works, works of authorship, applications, links and
            other content or materials (collectively, “Content”) that you submit, post or display on or via the Service.
            You must not change, modify, adapt or alter the Service or change, modify or alter another website so as to
            falsely imply that it is associated with the Service or FeelWell.
            You must not access FeelWell’s private API by means other than those permitted by FeelWell.
            You must not create or submit unwanted email, comments, likes or other forms of commercial or harassing
            communications (a/k/a “spam”) to any FeelWell users.
            You must not use domain names or web URLs in your username without prior written consent from FeelWell.
            You must not interfere or disrupt the Service or servers or networks connected to the Service, including by
            transmitting any worms, viruses, spyware, malware or any other code of a destructive or disruptive nature.
            You
            may not inject content or code or otherwise alter or interfere with the way any FeelWell page is rendered or
            displayed in a user’s browser or device.
            You must not create accounts with the Service through unauthorized means, including but not limited to, by
            using
            an automated device, script, bot, spider, crawler or scraper.
            You must not attempt to restrict another user from using or enjoying the Service and you must not encourage
            or
            facilitate violations of these Terms of Use or any other FeelWell terms.
            Violation of these Terms of Use may, in FeelWell’s sole discretion, result in termination of your FeelWell
            account. You understand and agree that FeelWell cannot and will not be responsible for the Content posted on
            the Service and you use the Service at your own risk. If you violate the letter or spirit of these Terms of
            Use,
            or otherwise create risk or possible legal exposure for FeelWell, we can stop providing all or part of the
            Service to you.
      <h1 style={{marginTop: 32, marginBottom: 12, textAlign: "center"}}>General Conditions</h1>
            We reserve the right to modify or terminate the Service or your access to the Service for any reason,
            without
            notice, at any time, and without liability to you.
            Upon termination, all licenses and other rights granted to you in these Terms of Use will immediately cease.
            We reserve the right, in our sole discretion, to change these Terms of Use (“Updated Terms”) from time to
            time.
            Unless we make a change for legal or administrative reasons, we will provide reasonable advance notice
            before
            the Updated Terms become effective. You agree that we may notify you of the Updated Terms by posting them on
            the
            Service, and that your use of the Service after the effective date of the Updated Terms (or engaging in such
            other conduct as we may reasonably specify) constitutes your agreement to the Updated Terms. Therefore, you
            should review these Terms of Use and any Updated Terms before using the Service. The Updated Terms will be
            effective as of the time of posting, or such later date as may be specified in the Updated Terms, and will
            apply
            to your use of the Service from that point forward. These Terms of Use will govern any disputes arising
            before
            the effective date of the Updated Terms.
            We reserve the right to refuse access to the Service to anyone for any reason at any time.
            We reserve the right to force forfeiture of any username for any reason.
            We may, but have no obligation to, remove, edit, block, and/or monitor Content or accounts containing
            Content
            that we determine in our sole discretion violates these Terms of Use.
            You are solely responsible for your interaction with other users of the Service, whether online or offline.
            You
            agree that FeelWell is not responsible or liable for the conduct of any user. FeelWell reserves the right,
            but has no obligation, to monitor or become involved in disputes between you and other users. Exercise
            common
            sense and your best judgment when interacting with others, including when you submit or post Content or any
            personal or other information.
            There may be links from the Service, or from communications you receive from the Service, to third-party web
            sites or features. There may also be links to third-party web sites or features in images or comments within
            the
            Service. The Service also includes third-party content that we do not control, maintain or endorse.
            Functionality on the Service may also permit interactions between the Service and a third-party web site or
            feature, including applications that connect the Service or your profile on the Service with a third-party
            web
            site or feature. For example, the Service may include a feature that enables you to share Content from the
            Service or your Content with a third party, which may be publicly posted on that third party’s service or
            application. Using this functionality typically requires you to login to your account on the third-party
            service
            and you do so at your own risk. FeelWell does not control any of these third-party web services or any of
            their content. You expressly acknowledge and agree that FeelWell is in no way responsible or liable for any
            such third-party services or features. YOUR CORRESPONDENCE AND BUSINESS DEALINGS WITH THIRD PARTIES FOUND
            THROUGH THE SERVICE ARE SOLELY BETWEEN YOU AND THE THIRD PARTY. You may choose, at your sole and absolute
            discretion and risk, to use applications that connect the Service or your profile on the Service with a
            third-party service (each, an “Application”) and such Application may interact with, connect to or gather
            and/or
            pull information from and to your Service profile. By using such Applications, you acknowledge and agree to
            the
            following: (i) if you use an Application to share information, you are consenting to information about your
            profile on the Service being shared; (ii) your use of an Application may cause personally identifying
            information to be publicly disclosed and/or associated with you, even if FeelWell has not itself provided
            such
            information; and (iii) your use of an Application is at your own option and risk, and you will hold the
            FeelWell Parties (defined below) harmless for activity related to the Application.
            You agree that you are responsible for all data charges you incur through use of the Service.
            We prohibit crawling, scraping, caching or otherwise accessing any content on the Service via automated
            means,
            including but not limited to, user profiles and photos (except as may be the result of standard search
            engine
            protocols or technologies used by a search engine with FeelWell’s express consent).
      <h1 style={{marginTop: 32, marginBottom: 12, textAlign: "center"}}>Rights</h1>
            FeelWell does not claim ownership of any Content that you post on or through the Service.
            You acknowledge that we may not always identify paid services, sponsored content, or commercial
            communications as such.
            All photos published on FeelWell are taken from the open public sources without any licenced or specific
            orders.
            If You find some content that belongs to You or You are the owner of some data published in our Service, You
            can contact us and we will remove it totally or mark it with your name.
            Also the Service can contain data owned or licensed by FeelWell (“FeelWell Content”). FeelWell Content is
            protected by copyright, trademark, patent, trade secret and other laws, and, as between you and FeelWell,
            FeelWell owns and retains all rights in the FeelWell Content and the Service. You will not remove, alter or
            conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in or
            accompanying the FeelWell Content and you will not reproduce, modify, adapt, prepare derivative works based
            on, perform, display, publish, distribute, transmit, broadcast, sell, license or otherwise exploit the
            FeelWell Content.
            The FeelWell name and logo are trademarks of FeelWell, and may not be copied, imitated or used, in whole or
            in part, without the prior written permission of FeelWell. In addition, all page headers, custom graphics,
            button icons and scripts are service marks, trademarks and/or trade dress of FeelWell, and may not be
            copied, imitated or used, in whole or in part, without prior written permission from FeelWell.
            Although it is FeelWell’s intention for the Service to be available as much as possible, there will be
            occasions when the Service may be interrupted, including, without limitation, for scheduled maintenance or
            upgrades, for emergency repairs, or due to failure of telecommunications links and/or equipment. Also,
            FeelWell reserves the right to remove any Content from the Service for any reason, without prior notice.
            Content removed from the Service may continue to be stored by FeelWell, including, without limitation, in
            order to comply with certain legal obligations, but may not be retrievable without a valid court order.
            Consequently, FeelWell encourages you to maintain your own backup of your Content. In other words, FeelWell
            is not a backup service and you agree that you will not rely on the Service for the purposes of Content
            backup or storage. FeelWell will not be liable to you for any modification, suspension, or discontinuation
            of the Services, or the loss of any Content. You also acknowledge that the Internet may be subject to
            breaches of security and that the submission of Content or other information may not be secure.
            You agree that FeelWell is not responsible for, and does not endorse, Content posted within the Service.
            FeelWell does not have any obligation to prescreen, monitor, edit, or remove any Content. If your Content
            violates these Terms of Use, you may bear legal responsibility for that Content.
            Except as otherwise described in the Service’s Privacy Policy, available at http://www.FeelWell.com/privacy,
            as between you and FeelWell, any Content will be non-confidential and non-proprietary and we will not be
            liable for any use or disclosure of Content. You acknowledge and agree that your relationship with FeelWell
            is not a confidential, fiduciary, or other type of special relationship, and that your decision to submit
            any Content does not place FeelWell in a position that is any different from the position held by members of
            the general public, including with regard to your Content. None of your Content will be subject to any
            obligation of confidence on the part of FeelWell, and FeelWell will not be liable for any use or disclosure
            of any Content you provide.
            It is FeelWell’s policy not to accept or consider content, information, ideas, suggestions or other
            materials other than those we have specifically requested and to which certain specific terms, conditions
            and requirements may apply. This is to avoid any misunderstandings if your ideas are similar to those we
            have developed or are developing independently. Accordingly, FeelWell does not accept unsolicited materials
            or ideas, and takes no responsibility for any materials or ideas so transmitted. If, despite our policy, you
            choose to send us content, information, ideas, suggestions, or other materials, you further agree that
            FeelWell is free to use any such content, information, ideas, suggestions or other materials, for any
            purposes whatsoever, including, without limitation, developing and marketing products and services, without
            any liability or payment of any kind to you.
            It is FeelWell’s policy not to accept or consider content, information, ideas, suggestions or other
            materials other than those we have specifically requested and to which certain specific terms, conditions
            and requirements may apply. This is to avoid any misunderstandings if your ideas are similar to those we
            have developed or are developing independently. Accordingly, FeelWell does not accept unsolicited materials
            or ideas, and takes no responsibility for any materials or ideas so transmitted. If, despite our policy, you
            choose to send us content, information, ideas, suggestions, or other materials, you further agree that
            FeelWell is free to use any such content, information, ideas, suggestions or other materials, for any
            purposes whatsoever, including, without limitation, developing and marketing products and services, without
            any liability or payment of any kind to you.
    </div>
  );
};

export default PrivacyPolicy;

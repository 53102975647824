import React, {Component} from "react";
import Fade, {Slide} from "react-reveal";

let key = 0;
// eslint-disable-next-line require-jsdoc
class About extends Component {
  render() {
    if (!this.props.data) return null;

    const promos = this.props.data.promos.map((promo) => {
      const projectImage = `images/promos/promo_${promo.image}.jpg`;

      return (
        <Slide left={key % 2 === 0} right={key % 2 !== 0} duration={1000}>
          <div key={key++}>
            <div style={{flexFlow: "wrap",
              display: "flex",
              flexWrap: "nowrap",
              flexDirection: "row",
              marginBottom: 20,
              paddingLeft: 10,
              paddingRight: 10,
            }}>
              {key % 2 === 0 ? <p style={{
                marginRight: 30,
              }}>{promo.text}</p> : <></>}
              <div style={{backgroundImage: `url("${projectImage}")`, backgroundSize: "cover", width: "100%", minWidth: "250px", height: "300px", paddingTop: 10, boxShadow: "0 0 4px 4px #e5e5e5 inset"}}></div>
              {key % 2 !== 0 ? <p style={{
                marginLeft: 30,
              }}>{promo.text}</p> : <></>}
            </div>
          </div>
        </Slide>
      );
    });

    return (
      <section id="about">
        <Fade bottom duration={2000} distance="100px">
          <div className="row">
            <h1>What We Do</h1>
            <div>
              {promos}
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;

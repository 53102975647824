import React from "react";
import ReactTextFormat from "react-text-format";

const PrivacyPolicy = () => {
  return (
    <div style={{padding: 32}}>
      <h1 style={{textAlign: "center"}}>Privacy Policy</h1>
      <h5 style={{marginBottom: 16, marginTop: 16}}>1. INFORMATION WE COLLECT</h5>
      <ReactTextFormat>
        We collect the following types of information.

        Information you provide us directly:

        Your username, password, e-mail and profile photo address when you register for an FeelWell account.
        Profile information that you provide for your user profile (e.g., first and/or last name). This information
        allows us to help you with better experience on FeelWell.
        Communications between you and FeelWell. For example, we may send you Service-related emails (e.g., account
        verification, changes/updates to features of the Service, technical and security notices). Note that you may not
        opt out of Service-related e-mails.

        Analytics information:

        We use third-party analytics tools to help us measure traffic and usage trends for the Service. These tools
        collect information sent by your device or our Service, including the web pages you visit, add-ons, and other
        information that assists us in improving the Service. We collect and use this analytics information with
        analytics information from other Users so that it cannot reasonably be used to identify any particular
        individual User.

        Cookies and similar technologies:

        When you visit the Service, we may use cookies and similar technologies like pixels, web beacons, and local
        storage to collect information about how you use FeelWell and provide features to you.
        We may ask advertisers or other partners to serve ads or services to your devices, which may use cookies or
        similar technologies placed by us or the third party.

        Device identifiers:

        When you use a mobile device like a tablet or phone to access our Service, we may access, collect, monitor,
        store on your device, and/or remotely store one or more “device identifiers.” Device identifiers are small data
        files or similar data structures stored on or associated with your mobile device, which uniquely identify your
        mobile device. A device identifier may be data stored in connection with the device hardware, data stored in
        connection with the device’s operating system or other software, or data sent to the device by FeelWell.
        A device identifier may deliver information to us or to a third party partner about how you browse and use the
        Service and may help us or others provide reports or personalized content and ads. Some features of the Service
        may not function properly if use or availability of device identifiers is impaired or disabled.
      </ReactTextFormat>
      <h5 style={{marginBottom: 16, marginTop: 16}}>2. HOW WE USE YOUR INFORMATION</h5>

        In addition to some of the specific uses of information we describe in this Privacy Policy, we may use
        information that we receive to:
        help you efficiently access your information after you sign in
        remember information so you will not have to re-enter it during your visit or the next time you visit the
        Service
        provide, improve, test, and monitor the effectiveness of our Service
        develop and test new products and features
        monitor metrics such as total number of visitors, traffic, and demographic patterns
        diagnose or fix technology problems
        automatically update the FeelWell application on your device

      <h5 style={{marginBottom: 16, marginTop: 16}}>3. SHARING OF YOUR INFORMATION</h5>

        We will not rent or sell your information to third parties outside FeelWell (or the group of companies of which
        FeelWell is a part) without your consent, except as noted in this Policy.
        Parties with whom we may share your information:

        We may share your information as well as information from tools like cookies, log files, and device identifiers
        and location data, with third-party organizations that help us provide the Service to you (“Service Providers”).
        Our Service Providers will be given access to your information as is reasonably necessary to provide the Service
        under reasonable confidentiality terms.
        We may remove parts of data that can identify you and share anonymized data with other parties. We may also
        combine your information with other information in a way that it is no longer associated with you and share that
        aggregated information.

      <h5 style={{marginBottom: 16, marginTop: 16}}>4. HOW WE STORE YOUR INFORMATION</h5>

        Storage and Processing:

        Your information collected through the Service may be stored and processed in the country in which FeelWell, its
        Affiliates or Service Providers maintain facilities.
        By registering for and using the Service you consent to the transfer of information to the country in which
        FeelWell, its Affiliates or Service Providers maintain facilities and the use and disclosure of information
        about you as described in this Privacy Policy.
        We use commercially reasonable safeguards to help keep the information collected through the Service secure and
        take reasonable steps (such as requesting a unique password) to verify your identity before granting you access
        to your account. However, FeelWell cannot ensure the security of any information you transmit to FeelWell or
        guarantee that information on the Service may not be accessed, disclosed, altered, or destroyed.
        Please do your part to help us. You are responsible for maintaining the secrecy of your unique password and
        account information, and for controlling access to emails between you and FeelWell, at all times. Your privacy
        settings may also be affected by changes the social media services you connect to FeelWell make to their
        services. We are not responsible for the functionality, privacy, or security measures of any other organization.


      <h5 style={{marginBottom: 16, marginTop: 16}}>5. YOUR CHOICES ABOUT YOUR INFORMATION</h5>

        Your account information and profile/privacy settings:

        Update your account at any time by logging in and changing your profile settings.
        Unsubscribe from email communications from us by clicking on the “unsubscribe link” provided in such
        communications. As noted above, you may not opt out of Service-related communications (e.g., account
        verification, purchase and billing confirmations and reminders, changes/updates to features of the Service,
        technical and security notices).


      <h5 style={{marginBottom: 16, marginTop: 16}}>6. OTHER WEB SITES AND SERVICES</h5>

        We are not responsible for the practices employed by any websites or services linked to or from our Service,
        including the information or content contained within them. Please remember that when you use a link to go from
        our Service to another website or service, our Privacy Policy does not apply to those third-party websites or
        services. Your browsing and interaction on any third-party website or service, including those that have a link
        on our website, are subject to that third party’s own rules and policies. In addition, you agree that we are not
        responsible and do not have control over any third-parties that you authorize to access your User Content. If
        you are using a third-party website or service and you allow them to access your User Content you do so at your
        own risk.

      <h5 style={{marginBottom: 16, marginTop: 16}}>7. HOW TO CONTACT US</h5>

        If you have any questions about this Privacy Policy or the Service, please find the appropriate support channel
        in the bottom of the screen.


      <h5 style={{marginBottom: 16, marginTop: 16}}>8. CHANGES TO OUR PRIVACY POLICY</h5>

        FeelWell may modify or update this Privacy Policy from time to time, so please review it periodically. We may
        provide you additional forms of notice of modifications or updates as appropriate under the circumstances. Your
        continued use of FeelWell or the Service after any modification to this Privacy Policy will constitute your
        acceptance of such modification.
    </div>
  );
};

export default PrivacyPolicy;
